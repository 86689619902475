import React, { useState, useMemo } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput"; // Import MDInput

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import noOneOnline from "assets/images/noOneOnline.png"

// Data
import onlinePlayersTableData from "layouts/online/data/onlinePlayersTableData";
import { useNavigate } from "react-router-dom";

function Tables() {
  const { columns, rows, loading } = onlinePlayersTableData();
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  // Filter rows based on search term
  const filteredRows = useMemo(() => {
    return rows.filter(row =>
      Object.values(row).some(value =>
        String(value).toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [rows, searchTerm]);

  // Modify columns to include clickable Player ID
  const updatedColumns = useMemo(() => {
    return columns.map((col) => {
      if (col.accessor === "id") {
        return {
          ...col,
          Cell: ({ value }) => (
            <MDButton
              variant="text"
              onClick={() => navigate(`/player/${value}`)}
              sx={{
                color: "gray !important",
                '&:hover': {
                  color: "blue !important",
                },
                cursor: "pointer",
              }}
            >
              {value}
            </MDButton>
          ),
        };
      }
      return col;
    });
  }, [columns, navigate]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDBox display="flex" justifyContent="space-between" alignItems="center" px={2} py={2}>
                  <MDTypography variant="h6" color="white">
                    Online Players
                  </MDTypography>
                  <MDBox display="flex" alignItems="center" style={{ position: 'relative' }}>
                    <MDInput
                      label="Search here"
                      value={searchTerm}
                      onChange={handleSearchChange}
                      InputProps={{
                        endAdornment: (
                          searchTerm && (
                            <IconButton
                              onClick={handleClearSearch}
                              edge="end"
                              style={{ padding: 0, marginRight: 8 }}
                            >
                              <CloseIcon />
                            </IconButton>
                          )
                        ),
                      }}
                      sx={{
                        '& .MuiInputBase-root': {
                          borderColor: 'green', // Apply green border color
                        },
                        '& .MuiInputLabel-root': {
                          color: 'green', // Apply green label color
                        },
                        paddingRight: '40px', // Ensure padding to avoid overlap with clear button
                      }}
                    />
                  </MDBox>
                </MDBox>
              </MDBox>
              <MDBox pt={3}>
                {loading ? (
                  <MDBox display="flex" justifyContent="center" alignItems="center" height="100px">
                    <CircularProgress />
                  </MDBox>
                ) : filteredRows.length > 0 ? (
                  <DataTable
                    table={{ columns: updatedColumns, rows: filteredRows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                ) : (
                  <MDBox display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
                    <MDBox component="img" src={noOneOnline} alt="No one online" width="60%" mt={1} />
                  </MDBox>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Tables;
