import React, { useState, useEffect } from "react";
import PlayerService from "services/player-service";
import { useParams } from "react-router-dom";

// Custom hook for fetching and formatting player data
export default function usePlayersTableData() {
  const [player, setPlayer] = useState(null);
  const [transactions, setTransactions] = useState(null);
  const [loading, setLoading] = useState(true); // Manage loading state
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      fetchPlayer(id);
    }
  }, [id]);

  const fetchPlayer = async (id) => {
    try {
      const data = await PlayerService.getPlayerById(id);
      console.log(data)
      if (data) {
        setPlayer(data.player);
        setTransactions(data.transactions)
      }
    } catch (error) {
      console.error("Error fetching player:", error);
    } finally {
      setLoading(false); // Set loading to false when data fetching completes
    }
  };

  const columns = [
    { Header: "ID", accessor: "id" },
    { Header: "Password", accessor: "password" },
    { Header: "Phone", accessor: "phone" },
    { Header: "Currency", accessor: "currency" },
    { Header: "Birthdate", accessor: "birthdate" },
    { Header: "Firstname", accessor: "firstname" },
    { Header: "Lastname", accessor: "lastname" },
    { Header: "Nickname", accessor: "nickname" },
    { Header: "Gender", accessor: "gender" },
    { Header: "Nationality", accessor: "nationality" },
    { Header: "Birth Place", accessor: "birth_place" },
    { Header: "Address", accessor: "address" },
    { Header: "Time Zone", accessor: "time_zone" },
    { Header: "Profession", accessor: "profession" },
    { Header: "Receive Email Promos At", accessor: "receive_email_promos_at" },
    { Header: "Receive SMS Promos At", accessor: "receive_sms_promos_at" },
    { Header: "Security Question", accessor: "security_question" },
    { Header: "Security Answer", accessor: "security_answer" },
    { Header: "Agreed T&C At", accessor: "agreed_tc_at" },
    { Header: "Bonus Code", accessor: "bonus_code" },
    { Header: "Confirmed At", accessor: "confirmed_at" },
    { Header: "Confirm Code", accessor: "confirm_code" },
    { Header: "Locked At", accessor: "locked_at" },
    { Header: "Created At", accessor: "created_at" },
    { Header: "Updated At", accessor: "updated_at" },
    { Header: "Email", accessor: "email" },
    { Header: "Status", accessor: "status" },
    { Header: "Reset Code", accessor: "reset_code" },
    { Header: "Country", accessor: "country" },
    { Header: "City", accessor: "city" },
    { Header: "Postal Code", accessor: "postal_code" },
    { Header: "Personal ID Number", accessor: "personal_id_number" },
    { Header: "Affiliate Tag", accessor: "affiliate_tag" },
    { Header: "Receive Bonus", accessor: "receive_bonus" },
    { Header: "Enabled Withdraw", accessor: "enabled_withdraw" },
    { Header: "Registration Country", accessor: "registration_country" },
    { Header: "Locale", accessor: "locale" },
    { Header: "Autologin Token", accessor: "autologin_token" },
    { Header: "Autologin Token Expires At", accessor: "autologin_token_expires_at" },
    { Header: "Freshchat Restore ID", accessor: "freshchat_restore_id" },
    { Header: "Segment IDs", accessor: "segment_ids" },
    { Header: "Region", accessor: "region" },
    { Header: "Finally Verified", accessor: "finally_verified" },
    { Header: "Allow Edit", accessor: "allow_edit" },
    { Header: "KYC Verified", accessor: "kyc_verified" },
    { Header: "Login", accessor: "login" },
    { Header: "IP Sign Up", accessor: "ip_sign_up" },
    { Header: "Reason Block", accessor: "reason_block" },
    { Header: "Online", accessor: "online" },
    { Header: "Last Online At", accessor: "last_online_at" },
    { Header: "Player ID", accessor: "player_id" },
    { Header: "External ID", accessor: "external_id" },
    { Header: "Amount", accessor: "amount" },
    { Header: "Provider", accessor: "provider" },
  ];

  // Define columns and rows for DataTable
  const rows = player
  ? [
      {
        id: id,
        password: player.password, // Handle with care
        phone: player.phone,
        currency: player.currency,
        birthdate: player.birthdate,
        firstname: player.firstname,
        lastname: player.lastname,
        nickname: player.nickname,
        gender: player.gender,
        nationality: player.nationality,
        birth_place: player.birth_place,
        address: player.address,
        time_zone: player.time_zone,
        profession: player.profession,
        receive_email_promos_at: player.receive_email_promos_at,
        receive_sms_promos_at: player.receive_sms_promos_at,
        security_question: player.security_question,
        security_answer: player.security_answer,
        agreed_tc_at: player.agreed_tc_at,
        bonus_code: player.bonus_code,
        confirmed_at: player.confirmed_at,
        confirm_code: player.confirm_code,
        locked_at: player.locked_at,
        created_at: player.created_at,
        updated_at: player.updated_at,
        email: player.email,
        status: player.status,
        reset_code: player.reset_code,
        country: player.country,
        city: player.city,
        postal_code: player.postal_code,
        personal_id_number: player.personal_id_number,
        affiliate_tag: player.affiliate_tag,
        receive_bonus: player.receive_bonus,
        enabled_withdraw: player.enabled_withdraw,
        registration_country: player.registration_country,
        locale: player.locale,
        autologin_token: player.autologin_token,
        autologin_token_expires_at: player.autologin_token_expires_at,
        freshchat_restore_id: player.freshchat_restore_id,
        segment_ids: player.segment_ids,
        region: player.region,
        finally_verified: player.finally_verified,
        allow_edit: player.allow_edit,
        kyc_verified: player.kyc_verified,
        login: player.login,
        ip_sign_up: player.ip_sign_up,
        reason_block: player.reason_block,
        online: player.online,
        last_online_at: player.last_online_at,
        player_id: player.player_id,
        external_id: player.external_id,
        amount: player.amount,
        provider: player.provider,
        device_type: player.device_type,
        user_agent: player.user_agent,
        gps_location: player.gps_location,
        geo_location: player.geo_location,
        ip: player.ip,
      },
    ]
    : [];
    

  return { columns, rows, transactions, loading };
}
