import axios from "axios";
import HttpService from "./htttp.service";

class AuthService {
  authEndpoint = process.env.REACT_APP_API_URL+"transaction/";

  // getAllTransactions = async () => {
  //   const transactionEndpoint = this.authEndpoint+'transactions';
  //   return await HttpService.get(transactionEndpoint);
  // };
  getAllTransactions = async () => {
    const transactionEndpoint = `${this.authEndpoint}transactions`;
    const token = this.getToken();

    try {
      const response = await axios.get(transactionEndpoint, {
        headers: {
          'Authorization': `Bearer ${token}` // Include token in Authorization header
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching transactions:', error);
      throw error;
    }
  };

  register = async (credentials) => {
    const registerEndpoint = 'register';
    return await HttpService.post(registerEndpoint, credentials);
  };

  logout = async () => {
    const logoutEndpoint = 'logout';
    return await HttpService.post(logoutEndpoint);
  };

  forgotPassword = async (payload) => {
    const forgotPassword = 'password-forgot';
    return await HttpService.post(forgotPassword, payload);
  }

  resetPassword = async (credentials) => {
    const resetPassword = 'password-reset';
    return await HttpService.post(resetPassword, credentials);
  }

  getProfile = async() => {
    const getProfile = 'me';
    return await HttpService.get(getProfile);
  }

  updateProfile = async (newInfo) => {
    const updateProfile = "me";
    return await HttpService.patch(updateProfile, newInfo);
  }
  getToken = () => {
    return localStorage.getItem('jwt_token');
  };
}

export default new AuthService();
