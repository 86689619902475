import React, { useState } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput"; // Make sure to import MDInput

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import operatorsTableData from "layouts/operators/data/operatorsTableData";

function Tables() {
  const { columns, rows, loading } = operatorsTableData();
  const [searchTerm, setSearchTerm] = useState('');

  // Filter rows based on search term
  const filteredRows = rows.filter(row =>
    Object.values(row).some(value =>
      String(value).toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDBox display="flex" justifyContent="space-between" alignItems="center" px={2} py={2}>
                  <MDTypography variant="h6" color="white">
                    Operators
                  </MDTypography>
                  <MDBox display="flex" alignItems="center"></MDBox>
                  <MDBox pr={1}>
                    <MDInput
                      label="Search here"
                      value={searchTerm}
                      onChange={handleSearchChange}
                      InputProps={{
                        endAdornment: (
                          searchTerm && (
                            <IconButton
                              onClick={handleClearSearch}
                              edge="end"
                              style={{ padding: 0, marginRight: 8 }}
                            >
                              <CloseIcon />
                            </IconButton>
                          )
                        ),
                      }}
                      sx={{
                        '& .MuiInputBase-root': {
                          borderColor: 'green', // Apply green border color
                        },
                        '& .MuiInputLabel-root': {
                          color: 'green', // Apply green label color
                        },
                        paddingRight: '40px', // Ensure padding to avoid overlap with clear button
                      }}
                    />
                  </MDBox>
                </MDBox>
              </MDBox>
              <MDBox pt={3}>
                {loading ? (
                  <MDBox display="flex" justifyContent="center" alignItems="center" height="100px">
                    <CircularProgress />
                  </MDBox>
                ) : (
                  <DataTable
                    table={{ columns, rows: filteredRows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              {/* Add other content or components here */}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Tables;
