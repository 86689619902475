import React, { useEffect, useState } from "react";
import TransactionService from "services/transaction-service";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import { io } from "socket.io-client";
import MDSnackbar from "components/MDSnackbar";

export default function TransactionsTableData() {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [successSB, setSuccessSB] = useState({ open: false, color: "success", message: "" });

  function updateTransactionBySocket(data) {
    setTransactions((prevTransactions) => {
      let updatedTransactions = [...prevTransactions];
  
      data.forEach((trans, index) => {
        let color = "success"; // Default color
        let message = "A new transaction was received.";
  
        if (trans.status === 2) { // Deposit
          color = "success";
          message = `A new deposit transaction was received. ID: ${trans.id}`;
        } else if (trans.status === 1) { // Pending
          color = "warning";
          message = `A transaction is pending. ID: ${trans.id}`;
        } else if (trans.status === 3) { // Withdrawal
          color = "error";
          message = `A rejection transaction was received. ID: ${trans.id}`;
        }
  
        // Trigger a notification for each transaction with a delay
        // setTimeout(() => {
        //   setSuccessSB({ open: true, color, message });
        // }, index * 4000); 
  
        const transactionIndex = updatedTransactions.findIndex(t => t.id === trans.id);
        if (transactionIndex !== -1) {
          updatedTransactions[transactionIndex] = { ...updatedTransactions[transactionIndex], ...trans };
        } else {
          updatedTransactions.push(trans);
        }
      });
  
      return updatedTransactions;
    });
  }
  

  function sortingTransactionsByField(arr, type) {
    return arr.slice().sort((a, b) => {
      const dateA = new Date(a?.created_at);
      const dateB = new Date(b?.created_at);
      
      if (type === 'desc') {
        return dateB.getTime() - dateA.getTime(); // Newer dates first
      } else {
        return dateA.getTime() - dateB.getTime(); // Older dates first
      }
    });
  }

  useEffect(() => {
    getAllTransactions();
    const socket = io.connect(process.env.REACT_APP_SOCKET);

    try {
      socket.on("transactionsSocket", (data) => {
        if (data) {
          updateTransactionBySocket(data);
        }
      });

    } catch (error) {
      console.error(error);
    }
  }, []);

  const getAllTransactions = async () => {
    setLoading(true);
    try {
      const data = await TransactionService.getAllTransactions();
      if (Array.isArray(data)) {
        setTransactions(data);
      } else {
        console.error("Fetched data is not an array:", data);
        setTransactions([]);
      }
    } catch (error) {
      console.error("Error fetching transactions:", error);
      setTransactions([]);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    { Header: "ID", accessor: "id", width: "25%", align: "left" },
    { Header: "Player ID", accessor: "player_id", align: "left" },
    { Header: "Type", accessor: "type", align: "left" },
    { Header: "Created At", accessor: "created_at", align: "center" },
    { Header: "Amount", accessor: "amount", align: "center" },
    { Header: "Currency", accessor: "currency", align: "center" },
    { Header: "Amount (base)", accessor: "base_currency_amount", align: "center" },
    { Header: "Currency (base)", accessor: "base_currency", align: "center" },
    { Header: "Status", accessor: "status", align: "center" },
    { Header: "Finished", accessor: "finished_at", align: "center" },
    { Header: "Updated At", accessor: "updated_at", align: "center" },
  ];

  const rows = sortingTransactionsByField(transactions, 'desc').map((transaction) => ({
    id: transaction.id,
    player_id: transaction.player_id,
    type: transaction.type === 1
      ? "Deposit"
      : transaction.type === 2
        ? "Withdrawal"
        : transaction.type === 3
          ? "Balance Correction"
          : transaction.type === 4
            ? "Purchase"
            : "Virtual Payout",
    created_at: transaction.created_at,
    amount: (
      <MDTypography color={transaction.status === 2 ? "success" : transaction.status === 1 ? "warning" : "error"}>
        {transaction.amount ? Number(transaction.amount) / 100000000 : null}
      </MDTypography>
    ),
    currency: (
      <MDTypography color={transaction.status === 2 ? "success" : transaction.status === 1 ? "warning" : "error"}>
        {transaction.currency}
      </MDTypography>
    ),
    base_currency_amount: (
      <MDTypography color={transaction.status === 2 ? "success" : transaction.status === 1 ? "warning" : "error"}>
        {transaction.base_currency_amount ? Number(transaction.base_currency_amount) / 100 : null}
      </MDTypography>
    ),
    base_currency: (
      <MDTypography color={transaction.status === 2 ? "success" : transaction.status === 1 ? "warning" : "error"}>
        {transaction.base_currency}
      </MDTypography>
    ),
    status: (
      <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
        <MDBadge
          badgeContent={transaction.status === 2 ? "Success" : transaction.status === 1 ? "Pending" : "Rejected"}
          color={transaction.status === 2 ? "success" : transaction.status === 1 ? "warning" : "error"}
          variant="gradient"
          size="sm"
        />
      </MDTypography>
    ),
    finished_at: transaction.finished_at ? transaction.finished_at : "-",
    updated_at: transaction.updated_at ? transaction.updated_at : "-",
    type1: transaction.type,
    status1: transaction.status,
    currency1: transaction.currency,
    amount1: transaction.base_currency_amount/100
  }));

  // const renderSuccessSB = (
  //   <MDSnackbar
  //     color={successSB.color}
  //     icon="notifications"
  //     title="Transaction Update"
  //     content={successSB.message}
  //     dateTime="Just now"
  //     open={successSB.open}
  //     onClose={() => setSuccessSB(prev => ({ ...prev, open: false }))}
  //     close={() => setSuccessSB(prev => ({ ...prev, open: false }))}
  //     bgWhite
  //     sx={{
  //       position: 'fixed',
  //       top: '20px',
  //       right: '20px',
  //       width: 'calc(100% - 40px)',
  //       maxWidth: '600px',
  //       height: 'auto',
  //     }}
  //   />
  // );

  return { columns, rows, loading, 
    // renderSuccessSB
   };
}
