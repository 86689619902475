import React, { useEffect, useState } from "react";
import DocumentService from "services/documents-service";
import convertCountries from "../../../helpers/convertCountries.json"; 

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import CircularProgress from "@mui/material/CircularProgress";

export default function documentsTableData() {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);


    // Function to convert country codes using the imported JSON
    const convertCountryCode = (countryCode) => {
      return convertCountries[countryCode] || countryCode; // Return full name if found, else return the code
    };

  useEffect(() => {
    fetchDocuments();
  }, []);

  const fetchDocuments = async () => {
    setLoading(true);
    try {
      const data = await DocumentService.getAllDocuments();
      if (data) {
        setDocuments(data);
      }
    } catch (error) {
      console.error("Error fetching players:", error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
      { Header: "Document Name", accessor: "original_file_name", width: "25%", align: "left" },
      { Header: "Document type", accessor: "type", align: "left" },
      { Header: "Status", accessor: "status", align: "center" },
      { Header: "Player ID", accessor: "player_id", width: "25%", align: "left" },
      { Header: "Uploaded At", accessor: "created_at", align: "center" },
      { Header: "Updated At", accessor: "updated_at", align: "center" }    
  ];

  const rows = documents.map((document) => ({
    original_file_name: (
      <MDTypography
        component="a"
        href={`https://manager.nabcasino.com/players/doc/${document.id}`}
        variant="caption"
        color="text"
        fontWeight="medium"
        style={{ textDecoration: 'none', color: '#0000EE' }} // Default link color
        onMouseEnter={(e) => e.currentTarget.style.color = '#1E90FF'} // Blue color on hover
        onMouseLeave={(e) => e.currentTarget.style.color = '#0000EE'} // Original color when not hovered
      >
        {document.original_file_name}
      </MDTypography>
    ),
    type: document.type,
    status: (
      <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
        <MDBadge
          badgeContent={document.status === 2 ? "Pending" : document.status === 1 ? "Approved" : "Rejected"}
          color={document.status === 2 ? "warning" : document.status === 1 ? "success" : "error"}
          variant="gradient"
          size="sm"
        />
      </MDTypography>
    ),
    player_id: document.player_id,
    created_at: document.created_at,
    updated_at: document.updated_at,
  }));

  return { columns, rows, loading };
}
