import React, { useState, useMemo } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput"; // Ensure this is imported

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import DataTable from "examples/Tables/DataTable";

// Data
import balancesTableData from "layouts/balances/data/balancesTableData";
import useReportsBarChartData from "layouts/balances/data/reportsBarChartData";

function Tables() {
  const { columns, rows } = balancesTableData();
  const { data, loading } = useReportsBarChartData();
  const [searchTerm, setSearchTerm] = useState('');

  const {
    totalBalances,
    totalBalancesChecked,
    totalBalancestBlocked,
  } = useMemo(() => {
    let totalBalances = 0;
    let totalBalancesChecked = 0;
    let totalBalancestBlocked = 0;

    rows.forEach(row => {
      const balance = Number(row.balance);
      totalBalances += balance;
      if (row.checkbox.props.checked) {
        totalBalancesChecked += balance;
      }
      if (row.blocked === 2 || row.blocked === 3) {
        totalBalancestBlocked += balance;
      }
    });

    return { totalBalances, totalBalancesChecked, totalBalancestBlocked };
  }, [rows]);

  const barChartData = useMemo(() => {
    const firstTen = {
      labels: data.labels.slice(0, 5),
      datasets: { label: data.datasets.label, data: data.datasets.data.slice(0, 10) },
    };

    const secondTen = {
      labels: data.labels.slice(5, 15),
      datasets: { label: data.datasets.label, data: data.datasets.data.slice(10, 20) },
    };

    const remain = {
      labels: data.labels.slice(15),
      datasets: { label: data.datasets.label, data: data.datasets.data.slice(20) },
    };

    return { firstTen, secondTen, remain };
  }, [data]);

  // Filter rows based on search term
  const filteredRows = rows.filter(row =>
    Object.values(row).some(value =>
      String(value).toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <MDBox mb={5}>
              <ComplexStatisticsCard
                color="dark"
                icon="weekend"
                title="Non blocked balances / Total balances"
                count={`${totalBalances - totalBalancestBlocked} / ${totalBalances}`}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={4}>
            <MDBox mb={5}>
              <ComplexStatisticsCard
                color="success"
                icon="weekend"
                title="Total balances Checked"
                count={totalBalancesChecked}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={4}>
            <MDBox mb={5}>
              <ComplexStatisticsCard
                color="primary"
                icon="weekend"
                title="Total balances blocked"
                count={totalBalancestBlocked}
              />
            </MDBox>
          </Grid>
        </Grid>

        {['warning', 'info', 'success'].map((color, index) => (
          <Grid item xs={12} md={4} key={index}>
            <MDBox mb={5}>
              {loading ? (
                <MDBox display="flex" justifyContent="center" alignItems="center" height="100%">
                  <CircularProgress color={color === 'warning' ? 'primary' : color} />
                </MDBox>
              ) : (
                <ReportsBarChart
                  color={color}
                  title={
                    index === 0
                      ? "Top 5 balances"
                      : index === 1
                      ? "Balances from 6th to 15th"
                      : "Balances from 16th and above"
                  }
                  chart={index === 0 ? barChartData.firstTen : index === 1 ? barChartData.secondTen : barChartData.remain}
                />
              )}
            </MDBox>
          </Grid>
        ))}

        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDBox display="flex" justifyContent="space-between" alignItems="center" px={2} py={2}>
                <MDTypography variant="h6" color="white">
                  Balances List
                </MDTypography>
                <MDBox pr={1}>
                  <MDInput
                    label="Search here"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    InputProps={{
                      endAdornment: (
                        searchTerm && (
                          <IconButton
                            onClick={handleClearSearch}
                            edge="end"
                            style={{ padding: 0, marginRight: 8 }}
                          >
                            <CloseIcon />
                          </IconButton>
                        )
                      ),
                    }}
                    sx={{
                      '& .MuiInputBase-root': {
                        borderColor: 'green', // Apply green border color
                      },
                      '& .MuiInputLabel-root': {
                        color: 'green', // Apply green label color
                      },
                      paddingRight: '40px', // Ensure padding to avoid overlap with clear button
                    }}
                  />
                </MDBox>
              </MDBox>
            </MDBox>
            <MDBox pt={3}>
              {loading ? (
                <MDBox display="flex" justifyContent="center" alignItems="center" height="100%">
                  <CircularProgress />
                </MDBox>
              ) : (
                <DataTable
                  table={{ columns, rows: filteredRows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              )}
            </MDBox>
          </Card>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Tables;
