import React, { useEffect, useState } from "react";
import pendingTransactionService from "services/pendingTransaction-service";

// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";
// import MDSnackbar from "components/MDSnackbar";


export default function pendingTransactionsTableData() {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);



  useEffect(() => {
    getAllTransactions();
  }, []);

  const getAllTransactions = async () => {
    setLoading(true);
    try {
      const data = await pendingTransactionService.getAllTransactions();
      if (Array.isArray(data)) {
        setTransactions(data);
      } else {
        console.error("Fetched data is not an array:", data);
        setTransactions([]);
      }
    } catch (error) {
      console.error("Error fetching transactions:", error);
      setTransactions([]);
    } finally {
      setLoading(false);
    }
    
  };

  const columns = [
    { Header: "ID", accessor: "id", width: "25%", align: "left" },
    { Header: "Player ID", accessor: "player_id", align: "left" },
    { Header: "Amount", accessor: "amount", align: "center" },
    { Header: "Currency", accessor: "currency", align: "center" },
    { Header: "Amount (base)", accessor: "base_currency_amount", align: "center" },
    { Header: "Currency (base)", accessor: "base_currency", align: "center" },
    { Header: "Created At", accessor: "created_at", align: "center" },
  ];

  const rows = transactions?.map((transaction) => ({
    id: transaction.id,
    player_id: transaction.player_id,
    amount: (
      <MDTypography color={transaction.type === 2 ? "error" : "text"}>
        {transaction.amount ? Number(transaction.amount) / 100000000 : null}
      </MDTypography>
    ),
    currency: transaction.currency,
    base_currency_amount: (
      <MDTypography color={transaction.type === 2 ? "error" : "text"}>
        {transaction.base_currency_amount ? Number(transaction.base_currency_amount) / 100 : null}
      </MDTypography>
    ),
    base_currency: transaction.base_currency,
    created_at: transaction.created_at,
  }));

  return { columns, rows, loading };
}
