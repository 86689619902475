import React, { useEffect, useState } from "react";
import balancesService from "services/balances-service";

const useReportsBarChartData = () => {
  const [data, setData] = useState({
    labels: [],
    datasets: { label: "Sales", data: [] },
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchReportsData();
  }, []);

  const fetchReportsData = async () => {
    setLoading(true);
    try {
      const data = await balancesService.getAllBalances();

      const labels = [];
      const salesValues = [];

      // Loop through the data to extract labels and data points
      data.forEach((item) => {
        labels.push(item.email);
        salesValues.push(item.balance);
      });

      // // Set the data to state in the required format
      setData({
        labels: labels,
        datasets: { label: "Balances", data: salesValues },
      });
    } catch (error) {
      console.error("Error fetching sales data:", error);
      setData({ labels: [], datasets: { label: "", data: [] } });
    } finally {
      setLoading(false);
    }
  };
//   const dataCopy = [...data];
// console.log(dataCopy)
  return { data, loading };
};

export default useReportsBarChartData;
