import React, { useEffect, useState } from "react";
import Operatorservice from "services/operator-service";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import CircularProgress from "@mui/material/CircularProgress";

export default function operatorsTableData() {
  const [operators, setOperators] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchOperators();
  }, []);

  const fetchOperators = async () => {
    setLoading(true);
    try {
      const data = await Operatorservice.getAllOperators();
      if (data) {
        setOperators(data);
      }
    } catch (error) {
      console.error("Error fetching Operators:", error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    { Header: "Operator ID", accessor: "id", width: "25%", align: "left" },
    { Header: "Email", accessor: "email", align: "left" },
    { Header: "Role", accessor: "name", align: "center" },
    { Header: "Created At", accessor: "created_at", align: "center" },
    { Header: "Updated At", accessor: "updated_at", align: "center" },
  ];

  const rows = operators.map((operator) => ({
    id: operator.id,
    email: operator.email,
    name: operator.name,
    created_at: operator.created_at,
    updated_at: operator.created_at,
    // status: (
    //   <MDBox ml={-1}>
    //     <MDBadge badgeContent={operator.status === 1 ? "Active" : (operator.status === 2 ? "Limited" : 'Blocked')} color={operator.status === 1 ? "success" : "dark"} variant="gradient" size="sm" />
    //   </MDBox>
    // ),
    // confirmed_at: (
    //   <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
    //     <MDBadge badgeContent={operator.confirmed_at ? "Confirmed" : 'Not Confirmed'} color={operator.confirmed_at ? "success" : "error"} variant="gradient" size="sm" />
    //   </MDTypography>
    // ),
  }));

  return { columns, rows, loading };
}
