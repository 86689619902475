import axios from "axios";
import HttpService from "./htttp.service";

class AuthService {
  authEndpoint = process.env.REACT_APP_API_URL+"document/";

  getAllDocuments = async () => {
    const documentsEndpoint = `${this.authEndpoint}documents`;
    const token = this.getToken();

    try {
      const response = await axios.get(documentsEndpoint, {
        headers: {
          'Authorization': `Bearer ${token}` // Include token in Authorization header
        }
      });
      
      return response.data;
    } catch (error) {
      console.error('Error fetching players:', error);
      throw error;
    }
  };

  getDocumentsByPlayerId = async (Id) => {
    console.log(Id)
    const documentsEndpoint = `${this.authEndpoint}documents/${Id}`;
    const token = this.getToken();

    try {
      const response = await axios.get(documentsEndpoint, {
        headers: {
          'Authorization': `Bearer ${token}` // Include token in Authorization header
        }
      });
      console.log(response.data)
      return response.data;
    } catch (error) {
      console.error('Error fetching documents:', error);
      throw error;
    }
  };

  register = async (credentials) => {
    const registerEndpoint = 'register';
    return await HttpService.post(registerEndpoint, credentials);
  };

  logout = async () => {
    const logoutEndpoint = 'logout';
    return await HttpService.post(logoutEndpoint);
  };

  forgotPassword = async (payload) => {
    const forgotPassword = 'password-forgot';
    return await HttpService.post(forgotPassword, payload);
  }

  resetPassword = async (credentials) => {
    const resetPassword = 'password-reset';
    return await HttpService.post(resetPassword, credentials);
  }

  getProfile = async() => {
    const getProfile = 'me';
    return await HttpService.get(getProfile);
  }

  updateProfile = async (newInfo) => {
    const updateProfile = "me";
    return await HttpService.patch(updateProfile, newInfo);
  }

  getToken = () => {
    return localStorage.getItem('jwt_token');
  };
}

export default new AuthService();
