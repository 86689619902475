/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  The linearGradient() function helps you to create a linear gradient color background
 */

  function linearGradient(color, colorState, angle = 195) {
    return `linear-gradient(${angle}deg, ${color}, ${colorState})`;
  }
  
  export default linearGradient;

  // ./assets/theme/functions/linearGradient.js

// const linearGradient = (color1, color2) => `
// linear-gradient(to right, ${color1}, ${color2})
// `;

// export default linearGradient;
