import React, { useState, useEffect, useMemo } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import DataTable from "examples/Tables/DataTable";
import CircularProgress from "@mui/material/CircularProgress";
import TransactionsTableData from "layouts/transactions/data/transactionsTableData";
import { useNavigate } from 'react-router-dom';


function Tables() {
  const { columns, rows, loading } = TransactionsTableData();
  const [selectedRow, setSelectedRow] = useState(null);
  const [totalDeposits, setTotalDeposits] = useState({ count: 0, amount: 0 });
  const [totalWithdrawals, setTotalWithdrawals] = useState({ count: 0, amount: 0 });
  const [totalBalanceCorrections, setTotalBalanceCorrections] = useState({ count: 0, amount: 0 });
  const [totalPurchases, setTotalPurchases] = useState({ count: 0, amount: 0 });
  const [totalVirtualPayouts, setTotalVirtualPayouts] = useState({ count: 0, amount: 0 });
  
  
  
  const [filterType, setFilterType] = useState("All");
  const [filterDateRange, setFilterDateRange] = useState("All");
  const [searchTerm, setSearchTerm] = useState('');

  const navigate = useNavigate();

  // Function to handle type filter change
  const handleTypeFilterChange = (type) => {
    setFilterType(type);
  };

  // Function to handle date range filter change
  const handleDateRangeFilterChange = (range) => {
    setFilterDateRange(range);
  };

  const handlePlayerIdClick = (playerID) =>{
    // console.log(playerID)
    navigate(`/player/${playerID}`);
  }
  // Filtered rows based on type and date range
  const filteredRows = useMemo(() => {
    let filtered = rows;

    // Filter by type
    if (filterType !== "All") {
      filtered = filtered.filter((row) => row.type === filterType);
    }

    // Filter by date range
    if (filterDateRange !== "All") {
      const now = new Date();
      let startDate = new Date();
      let endDate = new Date();
  
      switch (filterDateRange) {
        case "Last Day":
          startDate.setDate(now.getDate() - 1);
          endDate = now;
          break;
        case "Last Week":
          startDate.setDate(now.getDate() - 7);
          endDate = now;
          break;
        case "Last Month":
          startDate.setMonth(now.getMonth() - 1);
          startDate.setDate(1);
          endDate.setDate(0); // last day of the previous month
          break;
        case "This Month":
          startDate.setDate(1); // first day of the current month
          endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0); // last day of the current month
          break;
        case "Last 3 Months":
          startDate.setMonth(now.getMonth() - 3);
          endDate = now;
          break;
        case "Last 6 Months":
          startDate.setMonth(now.getMonth() - 6);
          endDate = now;
          break;
        case "Last Year":
          startDate.setFullYear(now.getFullYear() - 1);
          endDate = now;
          break;
        default:
          break;
      }
  
      filtered = filtered.filter((row) => {
        const rowDate = new Date(row.created_at);
        return rowDate >= startDate && rowDate <= endDate;
      });
    }
 // Filter by search term
 if (searchTerm) {
  filtered = filtered.filter(row => 
    Object.values(row).some(value =>
      String(value).toLowerCase().includes(searchTerm.toLowerCase())
    )
  );
}




    // Return filtered rows modified with click handlers and styles
    return filtered.map((row) => ({
      ...row,
      player_id: (
        <MDButton
          variant="text"
          onClick={() => handlePlayerIdClick(row.player_id)}
          sx={{
            color: "gray !important",
            '&:hover': {
              color: "blue !important",
            },
            cursor: "pointer",
          }}
        >
          {row.player_id}
        </MDButton>
      ),
      onClick: () => handleRowClick(row.id),
      sx: {
        backgroundColor: selectedRow === row.id ? "rgba(0, 123, 255, 0.1)" : "inherit",
        cursor: "pointer",
      },
    }));
  }, [rows, filterType, filterDateRange, selectedRow]);

  useEffect(() => {
    let depositCount = 0, depositAmount = 0;
    let withdrawalCount = 0, withdrawalAmount = 0;
    let balanceCorrectionCount = 0, balanceCorrectionAmount = 0;
    let purchaseCount = 0, purchaseAmount = 0;
    let virtualPayoutCount = 0, virtualPayoutAmount = 0;
  
    filteredRows.forEach((row) => {
      const amount = parseFloat(row.amount1) || 0; // Convert amount to a number, default to 0 if NaN
      switch (row.type) {
        case "Deposit":
          depositCount += 1;
          depositAmount += amount;
          break;
        case "Withdrawal":
          withdrawalCount += 1;
          withdrawalAmount += amount;
          break;
        case "Balance Correction":
          balanceCorrectionCount += 1;
          balanceCorrectionAmount += amount;
          break;
        case "Purchase":
          purchaseCount += 1;
          purchaseAmount += amount;
          break;
        case "Virtual Payout":
          virtualPayoutCount += 1;
          virtualPayoutAmount += amount;
          break;
        default:
          break;
      }
    });
  
    // Update state only if values change
    setTotalDeposits(prev => {
      if (prev.count !== depositCount || prev.amount !== depositAmount) {
        return { count: depositCount, amount: depositAmount };
      }
      return prev;
    });
    setTotalWithdrawals(prev => {
      if (prev.count !== withdrawalCount || prev.amount !== withdrawalAmount) {
        return { count: withdrawalCount, amount: withdrawalAmount };
      }
      return prev;
    });
    setTotalBalanceCorrections(prev => {
      if (prev.count !== balanceCorrectionCount || prev.amount !== balanceCorrectionAmount) {
        return { count: balanceCorrectionCount, amount: balanceCorrectionAmount };
      }
      return prev;
    });
    setTotalPurchases(prev => {
      if (prev.count !== purchaseCount || prev.amount !== purchaseAmount) {
        return { count: purchaseCount, amount: purchaseAmount };
      }
      return prev;
    });
    setTotalVirtualPayouts(prev => {
      if (prev.count !== virtualPayoutCount || prev.amount !== virtualPayoutAmount) {
        return { count: virtualPayoutCount, amount: virtualPayoutAmount };
      }
      return prev;
    });
  
  }, [filteredRows]);
  
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  
  const handleClearSearch = () => {
    setSearchTerm('');
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          {/* Total Deposits */}
          <Grid item xs={12} md={4}>
            <MDBox mb={3}>
              <ComplexStatisticsCard
                color="primary"
                icon="account_balance_wallet"
                title="Total Deposits / Total Amounts"
                count={`${totalDeposits.count} / ${(totalDeposits.amount).toFixed(2)}`}
              // count={`${totalDeposits.count} / ${totalDeposits.amount.toFixed(2)}`}
              />
            </MDBox>
          </Grid>

          {/* Total Withdrawals */}
          <Grid item xs={12} md={4}>
            <MDBox mb={3}>
              <ComplexStatisticsCard
                color="warning"
                icon="money_off"
                title="Total Withdrawals / Total Amounts"
                count={`${totalWithdrawals.count} / ${(totalWithdrawals.amount).toFixed(2)}`}
              />
            </MDBox>
          </Grid>

          {/* Total Balance Corrections */}
          <Grid item xs={12} md={4}>
            <MDBox mb={3}>
              <ComplexStatisticsCard
                color="secondary"
                icon="edit"
                title="Total Balance Corrections / Total Amounts"
                count={`${totalBalanceCorrections.count} / ${(totalBalanceCorrections.amount).toFixed(2)}`}
              />
            </MDBox>
          </Grid>

          {/* Total Purchases */}
          <Grid item xs={12} md={4}>
            <MDBox mb={3}>
              <ComplexStatisticsCard
                color="success"
                icon="shopping_cart"
                title="Total Purchases / Total Amounts"
                count={`${totalPurchases.count} / ${totalPurchases.amount}`}
              />
            </MDBox>
          </Grid>

          {/* Total Virtual Payouts */}
          <Grid item xs={12} md={4}>
            <MDBox mb={3}>
              <ComplexStatisticsCard
                color="info"
                icon="virtual_reality"
                title="Total Virtual Payouts / Total Amounts"
                count={`${totalVirtualPayouts.count} / ${totalVirtualPayouts.amount}`}
              />
            </MDBox>
          </Grid>

          {/* Table for filtered transactions */}
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                // variant="gradient"
                bgColor="lightblue" // Adjust this value to your preferred color
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  Transactions
                </MDTypography>
                <MDBox display="flex" gap={1}>
                  <MDButton
                    variant={filterType === "Deposit" ? "contained" : "outlined"}
                    color="primary"
                    onClick={() => handleTypeFilterChange("Deposit")}
                  >
                    Deposit
                  </MDButton>
                  <MDButton
                    variant={filterType === "Withdrawal" ? "contained" : "outlined"}
                    color="primary"
                    onClick={() => handleTypeFilterChange("Withdrawal")}
                  >
                    Withdrawal
                  </MDButton>
                  <MDButton
                    variant={filterType === "Balance Correction" ? "contained" : "outlined"}
                    color="primary"
                    onClick={() => handleTypeFilterChange("Balance Correction")}
                  >
                    Balance Correction
                  </MDButton>
                  <MDButton
                    variant={filterType === "Purchase" ? "contained" : "outlined"}
                    color="primary"
                    onClick={() => handleTypeFilterChange("Purchase")}
                  >
                    Purchase
                  </MDButton>
                  <MDButton
                    variant={filterType === "Virtual Payout" ? "contained" : "outlined"}
                    color="primary"
                    onClick={() => handleTypeFilterChange("Virtual Payout")}
                  >
                    Virtual Payout
                  </MDButton>
                  <MDButton
                    variant={filterType === "All" ? "contained" : "outlined"}
                    color="primary"
                    onClick={() => handleTypeFilterChange("All")}
                  >
                    All
                  </MDButton>
                  <MDBox pr={1}>
                    <MDInput
                       label="Search here"
                       value={searchTerm}
                       onChange={handleSearchChange}
                       InputProps={{
                         endAdornment: (
                           searchTerm && (
                             <IconButton
                               onClick={handleClearSearch}
                               edge="end"
                               style={{ padding: 0, marginRight: 8 }}
                             >
                               <CloseIcon />
                             </IconButton>
                           )
                         ),
                       }}
                      sx={{
                        '& .MuiInputBase-root': {
                          borderColor: 'red', // Apply red border color
                          borderWidth: '1px',
                          borderStyle: 'solid',
                        },
                        '& .MuiInputLabel-root': {
                          color: 'green', // Apply green label color
                        },
                        '& .MuiInputBase-root.Mui-focused': {
                          borderColor: 'red', // Apply red border color when focused
                          borderStyle: 'none', // Remove border style when focused
                          borderWidth: '0px', // Optional: Ensure no border width
                        }
                      }}
                      // onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </MDBox>
                </MDBox>

              </MDBox>
              <MDBox pt={3} px={2} display="flex" justifyContent="center" gap={2}>
                {/* Filter buttons for date ranges */}
                <MDButton variant={filterDateRange === "Last Day" ? "contained" : "outlined"} color="info" onClick={() => handleDateRangeFilterChange("Last Day")}>
                  Last Day
                </MDButton>
                <MDButton variant={filterDateRange === "Last Week" ? "contained" : "outlined"} color="info" onClick={() => handleDateRangeFilterChange("Last Week")}>
                  Last Week
                </MDButton>
                <MDButton variant={filterDateRange === "This Month" ? "contained" : "outlined"} color="info" onClick={() => handleDateRangeFilterChange("This Month")}>
                  This Month
                </MDButton>
                <MDButton variant={filterDateRange === "Last Month" ? "contained" : "outlined"} color="info" onClick={() => handleDateRangeFilterChange("Last Month")}>
                  Last Month
                </MDButton>
                <MDButton variant={filterDateRange === "Last 3 Months" ? "contained" : "outlined"} color="info" onClick={() => handleDateRangeFilterChange("Last 3 Months")}>
                  Last 3 Months
                </MDButton>
                <MDButton variant={filterDateRange === "Last 6 Months" ? "contained" : "outlined"} color="info" onClick={() => handleDateRangeFilterChange("Last 6 Months")}>
                  Last 6 Months
                </MDButton>
                <MDButton variant={filterDateRange === "Last Year" ? "contained" : "outlined"} color="info" onClick={() => handleDateRangeFilterChange("Last Year")}>
                  Last Year
                </MDButton>
                <MDButton variant={filterDateRange === "All" ? "contained" : "outlined"} color="info" onClick={() => handleDateRangeFilterChange("All")}>
                  All
                </MDButton>
              </MDBox>
              <MDBox pt={3}>
                {loading ? (
                  <MDBox display="flex" justifyContent="center" alignItems="center" height="100px">
                    <CircularProgress />
                  </MDBox>
                ) : (
                  <DataTable
                    table={{
                      columns: columns,
                      rows: filteredRows, // Use filteredRows here
                    }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Tables;
