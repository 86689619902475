import React, { useState, useMemo } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import TransactionsTableData from "layouts/transactions/data/transactionsTableData";
import MDButton from "components/MDButton";
import NothingFound from "../../assets/images/NothingFound.png";
import TransactionDetailsModal from "components/Modals/TransactionDetailsModal";
import { convertDateFormat } from "../../helpers/convertDateFormat";
import { useNavigate } from "react-router-dom";

function Tables() {
  const { columns, rows, loading } = TransactionsTableData();
  const [filteredColumns, setFilteredColumns] = useState(columns);
  const [filteredRows, setFilteredRows] = useState(rows);
  const [modalOpen, setModalOpen] = useState(false);
  const [noResultsFound, setNoResultsFound] = useState(false);
  const navigate = useNavigate();

  const handleOpenModal = () => {
    if (rows && rows.length > 0) {
      setFilteredRows(rows); 
      setNoResultsFound(false);
    }
    setModalOpen(true); 
  };
  
  const handleCloseModal = () => setModalOpen(false);

  const handleApplyFilters = (filters) => {
    const newFilteredRows = rows.filter((row) => {
      let matches = true;

      if (filters.ID && row.id !== filters.ID) {
        matches = false;
      }
      if (filters.playerId && row.player_id !== filters.playerId) {
        matches = false;
      }
      if (Number(filters.transactionType) && row.type1 !== Number(filters.transactionType)) {
        matches = false;
      }
      if (filters.createdAtFrom || filters.createdAtTo) {
        const filterDateFrom = convertDateFormat(filters.createdAtFrom);
        const filterDateTo = convertDateFormat(filters.createdAtTo);
        const rowDate = new Date(row.created_at)?.toISOString()?.split("T")[0];

        if (rowDate < filterDateFrom || rowDate > filterDateTo) {
          matches = false;
        }
      }

      if (filters.finishedAtFrom || filters.finishedAtTo) {
        const filterDateFrom = convertDateFormat(filters.finishedAtFrom);
        const filterDateTo = convertDateFormat(filters.finishedAtTo);

        if (row.finished_at !== "-") {
          const rowDate = new Date(row.finished_at)?.toISOString()?.split("T")[0];
          if (rowDate < filterDateFrom || rowDate > filterDateTo) {
            matches = false;
          }
        } else {
          matches = false;
        }
      }

      if (filters.currency && filters.currency.toLowerCase() !== row.currency1.toLowerCase()) {
        matches = false;
      }

      if (filters.amountFrom || filters.amountTo) {
        const amountFrom = filters.amountFrom ? Number(filters.amountFrom).toFixed(2) : (-Infinity).toFixed(2);
        const amountTo = filters.amountTo ? Number(filters.amountTo).toFixed(2) : (Infinity).toFixed(2);
        const rowAmount = Number(row.amount1).toFixed(2) / 100;

        if (rowAmount < amountFrom || rowAmount > amountTo) {
          matches = false;
        }
      }

      return matches;
    });

    if (newFilteredRows.length === 0) {
      setNoResultsFound(true);
    } 
    setFilteredRows(newFilteredRows);
    setFilteredColumns(columns);
    handleCloseModal();
  };

  // Modify columns to make `transaction_id` clickable
  const updatedColumns = useMemo(() => {
    return columns.map((col) => {
      if (col.accessor === "player_id") {
        return {
          ...col,
          Cell: ({ value }) => (
            <MDButton
              variant="text"
              onClick={() => navigate(`/player/${value}`)}
              sx={{
                color: "gray !important",
                "&:hover": {
                  color: "blue !important",
                },
                cursor: "pointer",
              }}
            >
              {value}
            </MDButton>
          ),
        };
      }
      return col;
    });
  }, [columns, navigate]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  Transactions List
                </MDTypography>
                <MDButton
                  variant="contained"
                  color="success"
                  onClick={handleOpenModal}
                  sx={{
                    "&:hover": {
                      backgroundColor: "darkblue",
                      color: "white",
                    },
                  }}
                >
                  Search Filter
                </MDButton>
              </MDBox>
              <MDBox pt={3}>
                {loading ? (
                  <MDBox display="flex" justifyContent="center" alignItems="center" height="100px">
                    <CircularProgress />
                  </MDBox>
                ) : noResultsFound ? (
                  <MDBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="800px"
                    style={{
                      backgroundImage: `url(${NothingFound})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    <MDTypography variant="body2" color="textSecondary">
                      <h1>Nothing found. Please try different filters.</h1>
                    </MDTypography>
                  </MDBox>
                ) : (
                  <DataTable
                    table={{
                      columns: updatedColumns.length > 0 ? updatedColumns : columns,
                      rows: filteredRows.length > 0 ? filteredRows : rows,
                    }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {/* Transaction Details Modal */}
      <TransactionDetailsModal
        open={modalOpen}
        handleClose={handleCloseModal}
        onApplyFilters={handleApplyFilters}
      />
    </DashboardLayout>
  );
}

export default Tables;
