import React, { useMemo, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput"; // Import MDInput for search functionality

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// Data
import documentsTableData from "layouts/documents/data/documentsTableData";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";

function Tables() {
  const { columns, rows, loading } = documentsTableData();
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  // Filter rows based on search term
  const filteredRows = rows.filter(row =>
    Object.values(row).some(value =>
      String(value).toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  // Modify columns to include clickable Player ID
  const updatedColumns = useMemo(() => {
    return columns.map((col) => {
      if (col.accessor === "player_id") {
        return {
          ...col,
          Cell: ({ value }) => (
            <MDButton
              variant="text"
              onClick={() => navigate(`/player/${value}`)}
              sx={{
                color: "gray !important",
                '&:hover': {
                  color: "blue !important",
                },
                cursor: "pointer",
              }}
            >
              {value}
            </MDButton>
          ),
        };
      }
      return col;
    });
  }, [columns, navigate]);

  // Clear search term
  const clearSearch = () => {
    setSearchTerm('');
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDBox display="flex" justifyContent="space-between" alignItems="center" px={2} py={2}>
                  <MDTypography variant="h6" color="white">
                    Documents
                  </MDTypography>
                  <MDBox pr={1} sx={{ position: 'relative' }}>
                    <MDInput
                      label="Search here"
                      value={searchTerm}
                      sx={{
                        '& .MuiInputBase-root': {
                          borderColor: 'green', // Apply green border color
                        },
                        '& .MuiInputLabel-root': {
                          color: 'green', // Apply green label color
                        },
                        '& .MuiInputBase-input': {
                          paddingRight: '40px', // Add padding to make space for the clear button
                        },
                      }}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    {searchTerm && (
                      <IconButton
                        onClick={clearSearch}
                        sx={{
                          position: 'absolute',
                          right: '10px',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          padding: 0,
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    )}
                  </MDBox>
                </MDBox>
              </MDBox>
              <MDBox pt={3}>
                {loading ? (
                  <MDBox display="flex" justifyContent="center" alignItems="center" height="100px">
                    <CircularProgress />
                  </MDBox>
                ) : (
                  <DataTable
                    table={{ columns: updatedColumns, rows: filteredRows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              {/* Add other content or components here */}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Tables;
