import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

import CircularProgress from "@mui/material/CircularProgress";
import Icon from "@mui/material/Icon";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";


// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// Data
import usePlayersTableData from "layouts/PlayerPage/data/playerData";
import Header from "./components/Header";

import PlatformSettings from "./components/PlatformSettings";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import profilesListData from "layouts/profile/data/profilesListData";

import convertCountries from "../../helpers/convertCountries.json";
import playerService from "services/player-service";





function Tables() {
  const { columns, rows, transactions, loading } = usePlayersTableData();

  if (loading) {
    return (
      <MDBox display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </MDBox>
    );
  }

  // const totalBalance = transactions.reduce((acc, transaction) => acc + Number(transaction?.base_currency_real_balance
  // ), 0);

  const deposits = transactions.filter(
    (transaction) => transaction.reference_type === "payment.deposit"
  );

  const lastDeposit = deposits.reduce((latest, transaction) => new Date(transaction.created_at) > new Date(latest.created_at) ? transaction : latest, deposits[0] || null);

  const withdrawals = transactions.filter(
    (transaction) => transaction.reference_type === "payment.withdrawal"
  );

  const lastWithdrawal = withdrawals.reduce((latest, transaction) => new Date(transaction.created_at) > new Date(latest.created_at) ? transaction : latest, withdrawals[0] || null);


  // Function to convert country codes using the imported JSON
  const convertCountryCode = (countryCode) => {
    return convertCountries[countryCode] || countryCode; // Return full name if found, else return the code
  };

  // Assuming the first row contains player data
  const playerData = rows[0] || {}; // Ensure fallback for empty row



  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox px={2} py={3}>
        <Header>
          <MDBox display="flex" alignItems="center" justifyContent="space-between" mb={3} mt={5}>
            <MDTypography variant="h5">{playerData.id || "N/A"}</MDTypography>
            <MDTypography variant="subtitle2" sx={{ color: playerData.online ? "success.main" : "red" }}>

              <MDBadge badgeContent={playerData.online ? "ONLINE" : "OFFLINE" || "N/A"} color={playerData.online ? "success" : "error"} variant="gradient" size="sm" />
              {/* {playerData.online ? "Online" : "Offline" || "N/A"} */}
            </MDTypography>
            {/* <MDTypography variant="body2">{playerData.email || "N/A"}</MDTypography> */}
            <MDTypography variant="subtitle2" color="success">
              <MDBadge badgeContent={playerData.status === 1 ? "Active" : playerData.status === 2 ? 'Limited' : "Blocked" || "N/A"} color={playerData.status === 1 ? "success" : playerData.status === 2 ? "error" : 'info'} variant="gradient" size="sm" />
            </MDTypography>
          </MDBox>

          {/* Balance and Deposit Stats */}
          <Grid container spacing={10}>
            <Grid item xs={12} md={2}>
              <Card>
                <MDBox p={2}>
                  <MDTypography variant="h6">Balance</MDTypography>
                  <MDTypography variant="h4">
                    {Number(transactions[0]?.base_currency_real_balance) / 100 || "$0"}
                  </MDTypography>
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12} md={2}>
              <Card>
                <MDBox p={2}>
                  <MDTypography variant="h6">Last Deposit</MDTypography>
                  <MDTypography variant="h4">
                  <span style={{ fontWeight: 'bold', color: 'green' }}>
                    {lastDeposit?.base_currency_real_amount / 100 || "$0"}
                    </span>
                  </MDTypography>
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12} md={2}>
              <Card>
                <MDBox p={2}>
                  <MDTypography variant="h6">All Deposits</MDTypography>
                  <MDTypography variant="h4">
                      {deposits.length}{' '}
                    <span style={{ fontWeight: 'bold', color: 'green' }}>
                    {deposits.length > 0
                      ? `$${deposits.reduce((sum, d) => sum + Number(d?.base_currency_real_amount) / 100, 0).toFixed(2)}`
                      : "$0"}
                      </span>
                  </MDTypography>
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12} md={2}>
              <Card>
                <MDBox p={2}>
                  <MDTypography variant="h6">Last Withdrawal</MDTypography>
                  <MDTypography variant="h4">
                  <span style={{ fontWeight: 'bold', color: 'red' }}>
                    {lastWithdrawal
                      ? `$${(lastWithdrawal.base_currency_real_amount / 100).toFixed(2)}, Date: ${new Date(lastWithdrawal.created_at).toLocaleDateString()}`
                      : "$0"}
                      </span>
                  </MDTypography>
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12} md={2}>
              <Card>
                <MDBox p={2}>
                  <MDTypography variant="h6">All Withdrawals</MDTypography>
                  <MDTypography variant="h4">
                      {withdrawals.length}
                    {' '}
                    <span style={{ fontWeight: 'bold', color: 'red' }}>
                    {withdrawals.length > 0
                      ? `$${withdrawals.reduce((sum, d) => sum + Number(d?.base_currency_real_amount) / 100, 0).toFixed(2)}`
                      : "$0"}
                      </span>
                  </MDTypography>
                </MDBox>
              </Card>
            </Grid>
          </Grid>


          <MDBox mt={5} mb={3}>
            <Grid container spacing={1}>
              {/* Profile Information Card */}
              <Grid item xs={12} md={6} xl={4}>
                <ProfileInfoCard
                  title="Profile Information"
                  info={{
                    playerID: playerData.id,
                    fullName: playerData.firstname,
                    nickName: playerData.nickname,
                    mobile: playerData.phone,
                    email: playerData.email,
                    location: playerData.country
                      ? convertCountryCode(playerData.country) || playerData.country
                      : null,
                  }}
                  action={{ route: "", tooltip: "Edit Profile" }}
                  shadow={true}
                />
              </Grid>

              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />

              {/* Engagement Details Card */}
              <Grid item xs={12} md={6} xl={4}>
                <ProfileInfoCard
                  title="Engagement Details"
                  info={{
                    DeviceType: playerData?.device_type,
                    ip: playerData.ip,
                    Country: playerData.country
                      ? convertCountryCode(playerData.country) || playerData.country
                      : null,
                    GPSLocation: playerData.gps_location,
                    GEOLOCATION: playerData.geo_location ? playerData.geo_location : null,
                    receivedBonus: playerData.receive_bonus ? 'True' : 'False',
                    receiveEmailPromosAt: playerData.receive_email_promos_at,
                    kycVerified: playerData.kyc_verified ? 'True' : 'False',
                  }}
                  action={{ route: "", tooltip: "Edit Profile" }}
                  shadow={true}
                />
              </Grid>

              <Divider orientation="vertical" sx={{ mx: 0 }} />

              {/* Additional Engagement Information Card */}
              <Grid item xs={12} md={6} xl={4}>
                <ProfileInfoCard
                  title="Additional Engagement Information"
                  info={{
                    GeoIPSignUp: playerData?.geo_ip_sign_up,
                    LastOnlineAt: playerData.last_online_at, // Corrected property
                    ipSignUp: playerData.ip_sign_up,
                    RegistrationCountry: playerData.registration_country
                      ? convertCountryCode(playerData.registration_country) || playerData.registration_country
                      : null,
                  }}
                  action={{ route: "", tooltip: "Edit Profile" }}
                  shadow={true}
                />
              </Grid>
            </Grid>
          </MDBox>

        </Header>
        {/* Header Section */}


        {/* Additional Player Info and Engagement */}
        {/* <MDBox pt={6} pb={3}>
          <Grid container spacing={3}>
            {/* Player Info Section */}
        {/* <Grid item xs={12} md={4}>
              <Card>
                <MDBox p={3}>
                  <MDTypography variant="h6">Player Info</MDTypography>
                  <MDBox mt={2}>
                    <MDTypography variant="body1">
                      Player ID: {playerData.id || "N/A"}
                    </MDTypography>
                    <MDTypography variant="body1">
                      Country: {playerData.country || "N/A"}
                    </MDTypography>
                    <MDTypography variant="body1">
                      Email: {playerData.email || "N/A"}
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>  */}

        {/* Engagement Section */}
        {/* <Grid item xs={12} md={4}>
              <Card>
                <MDBox p={3}>
                  <MDTypography variant="h6">Engagement</MDTypography>
                  <MDBox mt={2}>
                    <MDTypography variant="body1">
                      Loyalty Level: {playerData.loyaltyLevel || "N/A"}
                    </MDTypography>
                    <MDTypography variant="body1">
                      Comp Points: {playerData.compPoints || "N/A"}
                    </MDTypography>
                    <MDTypography variant="body1">
                      Quests Completed: {playerData.questsCompleted || "N/A"}
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid> */}

        {/* Security Section */}
        {/* <Grid item xs={12} md={4}>
              <Card>
                <MDBox p={3}>
                  <MDTypography variant="h6">Security</MDTypography>
                  <MDBox mt={2}>
                    <MDTypography variant="body1">
                      Geo IP Sign Up: {playerData.geoIpSignUp || "N/A"}
                    </MDTypography>
                    <MDTypography variant="body1">
                      IP Sign Up: {playerData.ip_sign_up || "N/A"}
                    </MDTypography>
                    <MDTypography variant="body1">
                      Last Online At: {playerData.last_online_at || "N/A"}
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid> */}
        {/* </Grid> */}

        {/* Transactions Section */}
        {/* <Grid container spacing={3} mt={3}>
            <Grid item xs={12}>
              <Card>
                <MDBox p={3}>
                  <MDTypography variant="h6">Transactions</MDTypography>
                  <MDBox mt={2}>
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid> */}
        {/* </MDBox> */}
      </MDBox>
    </DashboardLayout>
  );
}

export default Tables;
