import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import MDTypography from "../MDTypography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";

function TransactionDetailsModal({ open, handleClose, onApplyFilters }) {
  const defaultFilters = {
    ID: "",
    transactionType: "",
    playerId: "",
    createdAtFrom: "",
    createdAtTo: "",
    amountFrom: "",
    amountTo: "",
    currency: "",
    transactionStatus: "",
    transactionMethod: "",
    transactionProvider: "",
    finishedAtFrom: "",
    finishedAtTo: "",
    autoWithdrawal: "All",
  };

  const [filters, setFilters] = useState(defaultFilters);

  const handleChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  const handleClearField = (field) => {
    setFilters({
      ...filters,
      [field]: "",
    });
  };

  const handleResetFilters = () => {
    setFilters(defaultFilters);
  };

  const handleApplyFilters = () => {
    onApplyFilters(filters);
    handleClose();
  };

  const commonTextFieldProps = {
    fullWidth: true,
    InputProps: {
      sx: {
        height: 56,
        padding: "16px",
      },
    },
    InputLabelProps: {
      sx: {
        height: 56,
      },
    },
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: 600,
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <MDTypography id="modal-modal-title" variant="h6" component="h2">
          Filter Transactions
        </MDTypography>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <TextField
              {...commonTextFieldProps}
              label="Transaction ID"
              name="ID"
              value={filters.ID}
              onChange={handleChange}
              InputProps={{
                ...commonTextFieldProps.InputProps,
                endAdornment: filters.ID && (
                  <InputAdornment position="end">
                    <IconButton aria-label="clear" onClick={() => handleClearField("ID")} edge="end">
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <TextField
              {...commonTextFieldProps}
              select
              label="Transaction Type"
              name="transactionType"
              value={filters.transactionType}
              onChange={handleChange}
            >
              <MenuItem value="1">Deposit</MenuItem>
              <MenuItem value="2">Withdrawal</MenuItem>
              <MenuItem value="3">Balance Correction</MenuItem>
              <MenuItem value="4">Purchase</MenuItem>
              <MenuItem value="5">Virtual Payout</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <TextField
              {...commonTextFieldProps}
              label="Player ID"
              name="playerId"
              value={filters.playerId}
              onChange={handleChange}
              InputProps={{
                ...commonTextFieldProps.InputProps,
                endAdornment: filters.playerId && (
                  <InputAdornment position="end">
                    <IconButton aria-label="clear" onClick={() => handleClearField("playerId")} edge="end">
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6} sx={{ mb: 2 }}>
            <TextField
              {...commonTextFieldProps}
              label="Created At (From)"
              type="date"
              name="createdAtFrom"
              value={filters.createdAtFrom}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
                sx: {
                  height: 56,
                },
              }}
            />
          </Grid>
          <Grid item xs={6} sx={{ mb: 2 }}>
            <TextField
              {...commonTextFieldProps}
              label="Created At (To)"
              type="date"
              name="createdAtTo"
              value={filters.createdAtTo}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
                sx: {
                  height: 56,
                },
              }}
            />
          </Grid>
          <Grid item xs={6} sx={{ mb: 2 }}>
            <TextField
              {...commonTextFieldProps}
              label="Amount (From)"
              type="number"
              name="amountFrom"
              value={filters.amountFrom}
              onChange={handleChange}
              InputProps={{
                ...commonTextFieldProps.InputProps,
                endAdornment: filters.amountFrom && (
                  <InputAdornment position="end">
                    <IconButton aria-label="clear" onClick={() => handleClearField("amountFrom")} edge="end">
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6} sx={{ mb: 2 }}>
            <TextField
              {...commonTextFieldProps}
              label="Amount (To)"
              type="number"
              name="amountTo"
              value={filters.amountTo}
              onChange={handleChange}
              InputProps={{
                ...commonTextFieldProps.InputProps,
                endAdornment: filters.amountTo && (
                  <InputAdornment position="end">
                    <IconButton aria-label="clear" onClick={() => handleClearField("amountTo")} edge="end">
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <TextField
              {...commonTextFieldProps}
              select
              label="Currency"
              name="currency"
              value={filters.currency}
              onChange={handleChange}
            >
              <MenuItem value="BCH">BCH</MenuItem>
              <MenuItem value="BNB">BNB</MenuItem>
              <MenuItem value="BTC">BTC</MenuItem>
              <MenuItem value="DOGE">DOGE</MenuItem>
              <MenuItem value="ETH">ETH</MenuItem>
              <MenuItem value="LTC">LTC</MenuItem>
              <MenuItem value="TRX">TRX</MenuItem>
              <MenuItem value="USDC">USDC</MenuItem>
              <MenuItem value="USDT">USDT</MenuItem>
              <MenuItem value="XRP">XRP</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={6} sx={{ mb: 2 }}>
            <TextField
              {...commonTextFieldProps}
              label="Finished At (From)"
              type="date"
              name="finishedAtFrom"
              value={filters.finishedAtFrom}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
                sx: {
                  height: 56,
                },
              }}
            />
          </Grid>
          <Grid item xs={6} sx={{ mb: 2 }}>
            <TextField
              {...commonTextFieldProps}
              label="Finished At (To)"
              type="date"
              name="finishedAtTo"
              value={filters.finishedAtTo}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
                sx: {
                  height: 56,
                },
              }}
            />
          </Grid>
        </Grid>
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
          <Button variant="outlined" onClick={handleResetFilters} sx={{ ml: 40 }}>
            Reset
          </Button>
          <Button variant="contained" onClick={handleApplyFilters} sx={{ mr: 2 }}>
            Apply
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default TransactionDetailsModal;
