import React, { useEffect, useState } from "react";
import balancesService from "services/balances-service";
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import MDTypography from "components/MDTypography";
import Checkbox from "@mui/material/Checkbox";
import { io } from "socket.io-client";

export default function balancesTableData() {
  const [balances, setBalances] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    getAllBalances();
    setSelectedRows([]);

    // Initialize socket connection
    const socket = io.connect(process.env.REACT_APP_SOCKET);

    try {
      socket.on("balancesSocket", (data) => {
        console.log("SOCKET ON - Balances Data Received");
        if (data) {
          updateBalancesBySocket(data);
        }
      });
    } catch (error) {
      console.error("Socket error:", error);
    }

    // Cleanup socket connection on component unmount
    return () => {
      socket.disconnect();
    };
  }, []);

  const getAllBalances = async () => {
    setLoading(true);
    try {
      const data = await balancesService.getAllBalances();
      if (Array.isArray(data)) {
        setBalances(data);
      } else {
        console.error("Fetched data is not an array:", data);
        setBalances([]);
      }
    } catch (error) {
      console.error("Error fetching balances:", error);
      setBalances([]);
    } finally {
      setLoading(false);
    }
  };

  const updateBalancesBySocket = (data) => {
    setBalances((prevBalances) => {
      let updatedBalances = [...prevBalances];

      data.forEach((balance) => {
        const index = updatedBalances.findIndex((b) => b.id === balance.id);
        if (index !== -1) {
          // Update existing balance
          updatedBalances[index] = { ...updatedBalances[index], ...balance };
        } else {
          // Add new balance
          updatedBalances.push(balance);
        }
      });

      console.log("Number of balances updated:", data.length);
      return updatedBalances;
    });
  };

  const handleSelectRow = (id) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((rowId) => rowId !== id)
        : [...prevSelected, id]
    );
  };

  const handleSelectAllRows = () => {
    if (selectedRows.length === balances.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(balances.map((balance) => balance.id));
    }
  };

  const isAllSelected = selectedRows.length === balances.length;

  if (loading) {
    return {
      columns: [],
      rows: [],
    };
  }

  return {
    columns: [
      {
        Header: (
          <Checkbox
            checked={isAllSelected}
            onChange={handleSelectAllRows}
          />
        ),
        accessor: "checkbox",
        width: "5%",
        Cell: ({ row }) => (
          <Checkbox
            checked={selectedRows.includes(row.original.id)}
            onChange={() => handleSelectRow(row.original.id)}
          />
        ),
      },
      { Header: "Player ID", accessor: "id", align: "left" },
      { Header: "Created AT", accessor: "created_at", align: "center" },
      { Header: "Email", accessor: "email", align: "center" },
      { Header: "Status", accessor: "status", align: "center" },
      { Header: "Country", accessor: "country", align: "center" },
      {
        Header: "Registration Country",
        accessor: "registration_country",
        align: "center",
      },
      { Header: "ip Sign Up", accessor: "ip_sign_up", align: "center" },
      { Header: "Last Online At", accessor: "last_online_at", align: "center" },
      { Header: "Balances", accessor: "balances", align: "center" },
    ],

    rows: balances.map((balance) => ({
      checkbox: (
        <Checkbox
          checked={selectedRows.includes(balance.id)}
          onChange={() => handleSelectRow(balance.id)}
        />
      ),
      id: balance.id,
      created_at: balance.created_at,
      email: balance.email,
      status: (
        <MDBox ml={-1}>
          <MDBadge
            badgeContent={
              balance.status === 1 ? "Active" : balance.status === 2 ? "Limited" : "Blocked"
            }
            color={
              balance.status === 1 ? "success" : balance.status === 2 ? "warning" : "error"
            }
            variant="gradient"
            size="sm"
          />
        </MDBox>
      ),
      country: balance.country,
      registration_country: balance.registration_country,
      ip_sign_up: balance.ip_sign_up,
      last_online_at: balance.last_online_at,
      balances: (
        <MDTypography color={"error"}>
          {balance.balance ? Number(balance.balance) : null}
        </MDTypography>
      ),
      balance: balance.balance,
      blocked: balance.status,
    })),
  };
}
