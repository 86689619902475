/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Players from "layouts/players";
import Documents from "layouts/documents";
import Transactions from "layouts/transactions";
import PendingTransactions from "layouts/pendingTransactions";
import RejectedTransactions from "layouts/rejectedTransactions";
import Balances from "layouts/balances";
import Deposits from "layouts/deposits";
import Operators from "layouts/operators";
import Online from "layouts/online";


import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

import UserProfile from "layouts/user-profile";
import UserManagement from "layouts/user-management";

import Login from "auth/login";
import Register from "auth/register";
import ForgotPassword from "auth/forgot-password";
import ResetPassword from "auth/reset-password";

// @mui icons
import Icon from "@mui/material/Icon";
// import PlayerPage from "layouts/PlayerPage";

const routes = [
  {
    type: "collapse",
    name: "Deposits",
    key: "deposits",
    icon: <Icon fontSize="small">account_balance_wallet</Icon>,
    route: "/deposits",
    component: <Deposits />,
  },
  {
    type: "collapse",
    name: "Balances",
    key: "balances",
    icon: <Icon fontSize="small">account_balance</Icon>,
    route: "/balances",
    component: <Balances />,
  },
  {
    type: "collapse",
    name: "Rejected Transactions",
    key: "rejectedTransactions",
    icon: <Icon fontSize="small">cancel</Icon>,
    route: "/rejectedTransactions",
    component: <RejectedTransactions />,
  },
  {
    type: "collapse",
    name: "Pending Transactions",
    key: "pendingTransactions",
    icon: <Icon fontSize="small">hourglass_empty</Icon>,
    route: "/pendingTransactions",
    component: <PendingTransactions />,
  },
  {
    type: "collapse",
    name: "Transactions List",
    key: "transactions",
    icon: <Icon fontSize="small">list_alt</Icon>,
    route: "/transactions",
    component: <Transactions />,
  },
  {
    type: "collapse",
    name: "Players List",
    key: "players",
    icon: <Icon fontSize="small">people</Icon>,
    route: "/players",
    component: <Players />,
  },
  {
    type: "collapse",
    name: "Documents",
    key: "documents",
    icon: <Icon fontSize="small">description</Icon>,
    route: "/documents",
    component: <Documents />,
  },
  {
    type: "collapse",
    name: "Operators",
    key: "operator",
    icon: <Icon fontSize="small">supervisor_account</Icon>,
    route: "/operator",
    component: <Operators />,
  },
  {
    type: "collapse",
    name: "Online Players",
    key: "online",
    icon: <Icon fontSize="small">online_prediction</Icon>,
    route: "/online",
    component: <Online />,
  },
  // {
  //   type: "collapse",
  //   name: "Dashboard",
  //   key: "dashboard",
  //   icon: <Icon fontSize="small">dashboard</Icon>,
  //   route: "/dashboard",
  //   component: <Dashboard />,
  // },
  // {
  //   type: "collapse",
  //   name: "Tables",
  //   key: "tables",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/tables",
  //   component: <Tables />,
  // },
  // {
  //   type: "collapse",
  //   name: "Billing",
  //   key: "billing",
  //   icon: <Icon fontSize="small">receipt_long</Icon>,
  //   route: "/billing",
  //   component: <Billing />,
  // },
  // {
  //   type: "collapse",
  //   name: "RTL",
  //   key: "rtl",
  //   icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
  //   route: "/rtl",
  //   component: <RTL />,
  // },
  // {
  //   type: "collapse",
  //   name: "Notifications",
  //   key: "notifications",
  //   icon: <Icon fontSize="small">notifications</Icon>,
  //   route: "/notifications",
  //   component: <Notifications />,
  // },
  // {
  //   type: "collapse",
  //   name: "Profile",
  //   key: "profile",
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "/profile",
  //   component: <Profile />,
  // },
  // {
  //   type: "collapse",
  //   name: "Sign In",
  //   key: "sign-in",
  //   icon: <Icon fontSize="small">login</Icon>,
  //   route: "/authentication/sign-in",
  //   component: <SignIn />,
  // },
  // {
  //   type: "examples",
  //   name: "User Profile",
  //   key: "user-profile",
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "/user-profile",
  //   component: <UserProfile />,
  // },
  // {
  //   type: "examples",
  //   name: "User Management",
  //   key: "user-management",
  //   icon: <Icon fontSize="small">group</Icon>,
  //   route: "/user-management",
  //   component: <UserManagement />,
  // },
  // {
  //   type: "collapse",
  //   name: "Sign Up",
  //   key: "sign-up",
  //   icon: <Icon fontSize="small">how_to_reg</Icon>,
  //   route: "/authentication/sign-up",
  //   component: <SignUp />,
  // },
  {
    type: "auth",
    name: "Login",
    key: "login",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/auth/login",
    component: <Login />,
  },
  {
    type: "auth",
    name: "Register",
    key: "register",
    icon: <Icon fontSize="small">app_registration</Icon>,
    route: "/auth/register",
    component: <Register />,
  },
  {
    type: "auth",
    name: "Forgot Password",
    key: "forgot-password",
    icon: <Icon fontSize="small">lock_open</Icon>,
    route: "/auth/forgot-password",
    component: <ForgotPassword />,
  },
  {
    type: "auth",
    name: "Reset Password",
    key: "reset-password",
    icon: <Icon fontSize="small">lock</Icon>,
    route: "/auth/reset-password",
    component: <ResetPassword />,
  },
];

export default routes;
