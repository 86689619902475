import React, { useEffect, useState } from "react";
import PlayerService from "services/player-service";
import convertCountries from "../../../helpers/convertCountries.json"; // Import the country conversion JSON

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import CircularProgress from "@mui/material/CircularProgress";

export default function onlinePlayersTableData() {
  const [players, setPlayers] = useState([]);
  const [loading, setLoading] = useState(true);

  // Function to convert country codes using the imported JSON
  const convertCountryCode = (countryCode) => {
    return convertCountries[countryCode] || countryCode; // Return full name if found, else return the code
  };

  useEffect(() => {
    fetchPlayers();
  }, []);

  const fetchPlayers = async () => {
    setLoading(true);
    try {
      const data = await PlayerService.getAllPlayers();
      if (Array.isArray(data)) {
        // Filter players where online is true
        const onlinePlayers = data.filter((player) => player.online === true);
        setPlayers(onlinePlayers);
      } else {
        console.error("Unexpected data format:", data);
      }
    } catch (error) {
      console.error("Error fetching players:", error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    { Header: "Player ID", accessor: "id", width: "25%", align: "left" },
    { Header: "Email", accessor: "email", align: "left" },
    { Header: "Country", accessor: "country", align: "center" },
    { Header: "Status", accessor: "status", align: "center" },
    { Header: "Confirmation", accessor: "confirmed_at", align: "center" },
  ];

  const rows = players.map((player) => ({
    id: player.id,
    email: player.email,
    country: player.country ? (convertCountryCode(player.country) ? convertCountryCode(player.country): player.country) : null, // Convert country code
    status: (
      <MDBox ml={-1}>
        <MDBadge
          badgeContent={
            player.status === 1
              ? "Active"
              : player.status === 2
              ? "Limited"
              : "Blocked"
          }
          color={player.status === 1 ? "success" : "dark"}
          variant="gradient"
          size="sm"
        />
      </MDBox>
    ),
    confirmed_at: (
      <MDTypography
        component="a"
        href="#"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        <MDBadge
          badgeContent={player.confirmed_at ? "Confirmed" : "Not Confirmed"}
          color={player.confirmed_at ? "success" : "error"}
          variant="gradient"
          size="sm"
        />
      </MDTypography>
    ),
  }));
console.log(rows)
  return { columns, rows, loading };
}
